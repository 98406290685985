a// * {
//   font-family: "Nunito";
// }

// .sidebar {
//   flex: 1;
//   min-height: 100vh;
//   background-color: #085394;
//   overflow-y: auto;
//   overflow-x: hidden;

//   .top {
//     height: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .logo {
//       height: auto;
//       width: 180px;
//     }
//   }

//   hr {
//     height: 0;
//     border: 0.5px solid white;
//   }

//   .center {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     border-top: 2px solid #fff;
//     border-bottom: 2px solid #fff;
//     padding: 10px 0;

//     ul {
//       list-style: none;
//       width: 100%;

//       .sidebarLink {
//         text-decoration: none;
//         display: flex;
//         justify-content: flex-end;
//       }

//       .title {
//         font-size: 10px;
//         font-weight: bold;
//         color: white;
//         margin-top: 15px;
//         margin-bottom: 5px;
//       }

//       li {
//         display: flex;
//         margin: 10px 0;
//         padding: 4px 10px;
//         font-size: 15px;
//         cursor: pointer;
//         background-color: #085394;
//         width: 90%;

//         span {
//           font-size: 15px;
//           font-weight: 600;
//           color: #d0d5e4;
//           margin-left: 10px;
//         }
//       }
//     }
//   }

//   .bottom {
//     display: flex;
//     align-items: center;
//     margin: 10px;

//     .colorOption {
//       width: 20px;
//       height: 20px;
//       border-radius: 5px;
//       border: 1px solid white;
//       cursor: pointer;
//       margin: 5px;

//       &:nth-child(1) {
//         background-color: whitesmoke;
//       }
//       &:nth-child(2) {
//         background-color: #333;
//       }
//       &:nth-child(3) {
//         background-color: white;
//       }
//     }
//   }
// }

// .icon {
//   font-size: 25px; // Adjusted font size for icons
//   color: #d0d5e4;
//   margin-right: 3px;
// }

// .accordion-item:first-of-type .accordion-button {
//   background-color: #1b2135;
//   border: none;
//   color: #ffffff;
// }

// .classBtns {
//   background-color: #085394;
//   border: none;
//   font-size: 15px;
//   font-weight: 600;
//   color: #d0d5e4;
// }

// .upperDiv {
//   width: 100%;
//   margin-left: 30px;
//   margin-top: 10px;
// }

// .dropDownList {
//   color: white;
//   text-decoration: none;
//   font-size: 15px;
//   font-weight: 400;
//   color: #d0d5e4;
// }

// .dropDownList span {
//   padding-left: 50px;
// }

// .css-14nj4gg-MuiSvgIcon-root,
// .navbar .wrapper .search,
// .navbar .wrapper .items {
//   position: relative;
//   top: -17px;
// }

// .spanfont {
//   font-size: 12px !important;
//   margin: 2px 0px !important;
// }

// .dashboardTxt {
//   position: relative;
// }

// #example-collapse-text {
//   padding: 0 0 0 30px;
// }

// .dashBoardTxticon {
//   margin-bottom: 10px;
// }

// .linkFixed {
//   position: relative;
//   margin-top: 20px;
// }

// @media (max-width: 512px) {
//   #sidebar {
//     display: none;
//     width: 100%;
//   }
// }

// .active {
//   position: relative;
//   background-color: #eaf2ff !important;
//   border-radius: 20px 0 0 20px;
//   width: 100%;

//   &::before {
//     content: "";
//     position: absolute;
//     width: 11px;
//     height: 24px;
//     right: 0;
//     top: -24px;
//     border-radius: 0 0 20px 0;
//     box-shadow: 0 10px 0 0 #eaf2ff;

//   }

//   &::after {
//     content: "";
//     position: absolute;
//     width: 11px;
//     height: 24px;
//     right: 0;
//     bottom: -24px;
//     border-radius: 0 20px 0 0;
//     box-shadow: 0 -10px 0 0 #eaf2ff;

//   }
// }

// .active-collapse {
//   background-color: #eaf2ff !important;
//   border-radius: 20px 0 0 20px;
//   padding: 5px;
// }

// .active-font {
//   font-weight: 600;
//   color: black;
// }
// .active-icon {
//   color: #629bf5;
// }

// .collapeBtn {
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// }

// .collapeLink {
//   text-decoration: none;
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   color: #d0d5e4;
// }

// .toggle-button{
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background-color: #085394;
//   display: none;
// }

// @media (max-width: 768px) {
//   .sidebar {
//     display: none;
//   }

//   .toggle-button{
//     display: block;
//   }

// }

// .active-collapse{
//   background-color: #e8f0fe;
//   border-radius: 20px 0 0 20px;
//   width: 75%;
//   padding: 4px;
// }

* {
  font-family: "Nunito";
}

.sidebar {
  min-height: 100vh;
  background-color: #085394;
  overflow-y: auto;
  overflow-x: hidden;
  min-width:175px;
  width:auto;

  .top {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: auto;
      width: 180px;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid white;
  }
}

/* Sidebar.css */
.sidebar-container {
  border-top: 3px solid #eaf2ff;
  border-bottom: 3px solid #eaf2ff;
  padding-top: 15px;
}

.sidebar-link {
  display: flex;
  margin-left: 20%;
  width: 80%;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 20px;
  padding: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.sidebar-link.active {
  background-color: #eaf2ff !important;
  color: #629bf5;
  // font-weight: 800;
  padding: 2px 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative; 
  min-height:35px;/* Add position relative to enable absolute positioning for pseudo-elements */
}

.sidebar-link.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: -30px;
  height: 30px;
  width: 12px;
  border-radius: 0 0 30px 0;
  box-shadow: 0 15px 0 0 #eaf2ff;
}

.sidebar-link.active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -30px;
  height: 30px;
  width: 12px;
  border-radius: 0 30px 0 0;
  box-shadow: 0 -15px 0 0 #eaf2ff;
}

.icon-wrapper {
  margin-right: 10px;
}

.lable {
  font-size: 14px;
}

.collapsible-item {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  margin-left: 20%;
  width: 80%;
}

.arrow {
  position: absolute;
  right: 10px;
  font-size: 12px;
  transition: transform 0.3s ease;
  
}

.collapsible-item.open .arrow {
  transform: rotate(-180deg);
}

.submenu {
  margin-left: 20px;
}

// @media (max-width: 768px) {
//   .sidebar-container {
//     display: none;
//   }
// }

// @media (min-width: 768px) {
//   .mobile-sidebar-container {
//     display: none;
//   }
// }
