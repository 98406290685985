.loneReq {
  display: flex;
}
.lonenav {
  flex: 6;
}
.page-content {
  flex-grow: 1;
  padding: 25px;
}
.page-title {
  margin-bottom: 15px;
}
.page-title h1 {
  position: relative;
}
.page-title h1 {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 400;
}
.page-title h1::after {
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: -moz-linear-gradient(
    left,
    #1b2135 0,
    #1b2135 8%,
    #d5d8e2 8%,
    #d5d8e2 66%,
    #d5d8e2 66%,
    #d5d8e2 100%
  );
  background: -webkit-linear-gradient(
    left,
    #1b2135 0,
    #1b2135 8%,
    #d5d8e2 8%,
    #d5d8e2 66%,
    #d5d8e2 66%,
    #d5d8e2 100%
  );
  background: linear-gradient(
    to right,
    #1b2135 0,
    #1b2135 8%,
    #d5d8e2 8%,
    #d5d8e2 66%,
    #d5d8e2 66%,
    #d5d8e2 100%
  );
}
section {
  display: block;
}
section .row-form {
  display: flex;
  flex-wrap: wrap;
}

.col-md-8 {
  max-width: 100% !important;
  flex: 0 0 100%;
}
.form-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin: 5px 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f2f4f9;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);
  -webkit-box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);
  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
}
.cord-body {
  flex: 1 1 auto;
  padding: 30px;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.toolbars {
  display: flex;
  gap: 25px;
  flex-direction: row;
}
.toolbars label {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}
.toolbars .search input[type="search"] {
  height: 35px;
  width: auto;
  padding-left: 10px;
  outline: none;
  border-radius: 4px;
  margin-left: 8px;
  text-align: center;
  border: 1px solid #d5d7e1;
}
.toolbars .addClient button {
  display: flex;
  color: #fff;
  background-color: #1b2135;
  padding: 10px 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid gray;

  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.cord-body table.dataTable {
  margin-top: 20px !important;
}
// table.dataTable {
// 	clear: both;
// 	margin-top: 6px !important;
// 	margin-bottom: 6px !important;
// 	max-width: none !important;
// 	border-collapse: separate !important;
// 	border-spacing: 0;
// }
// .listDataTable thead {
// 	background: #3c476b;
// 	color: #fff;
// }
// #clientListDataTable {
// 	display: table;
// }
// .table-responsive table.listDataTable {
// 	display: block;
// 	width: 100%;
// 	overflow-x: auto;
// 	-webkit-overflow-scrolling: touch;
// 	border: 1px solid #d5d8e2;
// }
// .listDataTable thead tr {
// 	display: table-row;
// 	vertical-align: inherit;
// 	border-color: inherit;
// }
// .listDataTable thead th {
// 	text-align: inherit;
// 	padding: 10px 5px;
// 	line-height: 1.3em;
// 	white-space: nowrap;
// 	cursor: pointer;
// 	font-weight: 400;
// }
// table.listDataTable td,
// table.listDataTable th {
// 	border-left: 1px solid #d5d8e2;
// 	border-bottom: 1px solid #d5d8e2;
// 	padding: 10px 12px;
// 	font-size: 14px;
// }
// table.listDataTable .select2 {
// 	height: 30px;
// 	width: 120px;
// 	font-size: 14px;
// 	border-radius: 4px;
// 	border: 1px solid #d5d7e1;
// 	outline: none;
// }

.formcontrol {
  /* padding: 50px; */
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // padding: 10px;
  // margin-left: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.formcontrol label {
  font-size: 14px;
  font-weight: 600;
  color: #1b2135;
  margin-right: 10px;
}
.formcontrol input[type="text"] {
  width: 340px;
  height: 40px;
  padding: 5px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid;
  outline: none;
}
.formcontrol input[type="radio"] {
  margin-right: 5px;
  padding: 19px;
}
button.SaveTask {
  margin-left: 50%;
  width: auto;
  height: 50px;
  width: 20%;
  padding: 10px 16px;
  background: #1b2135;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #1b2135;
  border-radius: 4px;
  margin-top: 12px;
}

.formcontrol input[type="checkbox"] {
  margin-left: 50px;
}

//Permission page css//
.switch-field {
  display: flex;
  margin-bottom: -5px;
}
.switch {
  display: flex;
  align-items: center;
}
.switch-field .switch input[type="radio"] {
  display: none;
}
.switch-field .switch label {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  color: #5165a8;
  cursor: pointer;
  border: 1px solid #d5d7e1;
  text-align: inherit;
  position: relative;
}
.switch-field .switch input:checked + label {
  border-radius: 0 4px 4px 0;
  background-color: #1b2135;
  border: 0;
  color: #fff;
}
.cmain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customFilter {
  margin-left: 585px;
  display: flex;
  list-style: none;
  gap: 10px;
  margin-bottom: 6px;
}

a.nav_link {
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 1px 10px;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  color: #5165a8;
  cursor: pointer;
  border: 0.2px solid #d5d7e1;
  text-align: inherit;
  position: relative;

  border-radius: 5px;
  background-color: #1b2135;
  border: 0;
  color: #fff;
  float: right;
}
.selectinput1 {
  cursor: pointer;
  margin-right: 20px;
  width: 150px;
  height: 40px;
  padding: 5px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid;
  outline: none;
}
// ====================datemain======================//
.search {
  display: flex;
}

.selectDateRangeDiv {
  // margin-top: 10px;
  gap: 5px;
  display: flex;
}
.trackfrom {
  margin-top: 7px;
}

.datetex {
  cursor: pointer;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  height: 40px;
  width: 130px;
  font-size: 15px;
  border: 0.8px solid black;
  border-radius: 5px;
  background-color: white;
}

.btnapply {
  width: 70px;
  height: 40px;
  background-color: #1b2135;
  color: white;
}
.ProjectHour {
  width: 90%;
  background: transparent;
  margin: 2%;
}

.download-table-xls-button {
  margin-top: 20px;
  float: right;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .col-md-8 {
    flex: 0 0 auto;
    width: 100% !important;
  }
  button.SaveTask {
    width: 35%;
  }
}
@media (max-width: 512px) {
  .toolbars {
    display: flex;
    gap: 25px;
    justify-content: center;
    flex-direction: column;
  }
  .toolbars .search {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .selectDateRangeDiv {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
  .selectinput1 {
    width: 100%;
  }
  .datetex {
    width: 100%;
  }
}

.calendar_2 {
  width: 600px;
  margin: 0 auto;
  background-color: #fff; /* White background */
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  gap: 10px;
}

.calendar_navigation {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  flex-wrap: wrap;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 5px;
}

.calendar_container {
  width: 90%;
}

.days_2 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  padding: 8px;
  font-size: 13px;
  background-color: white;
}

.day_2 {
  display: flex;
  padding: 5px;
  height: 50px;
  cursor: pointer;
  font-size: 8px;
  background-color: #ebf2f7 !important;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
}

.day-of-week {
  color: gray;
  font-weight: bold;
  padding: 2px 0;
  font-size: 6px;
}

/* Rename your existing CSS classes and create new ones */
.section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-item {
  width: 150px;
  text-align: center;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  // border: 2px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: background-color 0.3s ease-in-out;
}

.event_icon {
  font-size: 10px;
}

.section-icon {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 5px;
}

.section-text {
  font-weight: bold;
  font-size: 12px;
}
/* Update your CSS to style the icons */
.section-icon {
  width: 50px;
  height: 50px;
  background-color: #3498db; /* Blue by default */
  border-radius: 50%;
  margin-bottom: 5px;
}

.icon-selected {
  background-color: #fff; /* White when selected */
  color: #3498db; /* Blue text when selected */
}

/* Detailedsheet.scss */

.calendar_details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.leave-date-hr {
  border-top: 3px solid blue; /* Define the border style for highlighting */
  width: 100%; /* Make the line full width of the container */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
}

/* Style for the category background */
.category-background {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for the "leaves-background" category */
.leaves-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://blog.vantagecircle.com/content/images/2023/04/national-leave-the-office-early-day.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Style for the "birthdays-background" category */
.birthdays-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://media.istockphoto.com/id/1188867180/photo/birthday-party-banner.jpg?s=1024x1024&w=is&k=20&c=NQnvhTdE2oOyoCRWAGnpKQFKiBejolaqznmkvua9L-U=");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

/* Style for the "holidays-background" category */
.holidays-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://st.depositphotos.com/1000970/2346/i/450/depositphotos_23467114-stock-photo-couple-on-a-beach.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Style for the "project_start-background" category */
.project_start-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://cdn.wedevs.com/uploads/2015/02/How-to-Create-and-Manage-Projects-using-WordPress.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Style for the "project_end-background" category */
.project_end-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("https://desktime.com/blog/wp-content/uploads/2021/08/meeting.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.date-item {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  color: #3498db;
  font-size: 13px;
  font-weight: 700;
}

.text {
  font-weight: bold;
}
