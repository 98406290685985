.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;
  background-color: white;
  width: 100%;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .title {
      font-weight: bold;
      font-size: 12px;
      color: rgb(45, 43, 43);
    }

    .counter {
      font-size: 28px;
      font-weight: 300;
    }

    .link {
      width: max-content;
      font-size: 12px;
      color: aqua;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        
          color: #006000;
          font-weight: 600;
          font-size: 16px;
      
      }
      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 27px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
    }
  }
}
