.custom-container {
  background: rgb(99, 39, 120);

}

.custom-input:focus {
  box-shadow: none;
  border-color: #ba68c8;
}

.custom-primary {
  background: rgb(99, 39, 120);
  box-shadow: none;
  border: none;
}

.custom-primary:hover {
  background: #682773;
}

.custom-primary:focus {
  background: #682773;
  box-shadow: none;
}

.custom-primary:active {
  background: #682773;
  box-shadow: none;
}

.custom-border-right {
  border-right: 1px solid #000;
}

.custom-font-weight {
  font-weight: bold;
}

.custom-text-muted {
  color: #000;
}

.custom-text-right {
  text-align: right;
}

.custom-label {
  font-size: 11px;
}

.custom-add-experience:hover {
  background: #ba68c8;
  color: #fff;
  cursor: pointer;
  border: solid 1px #ba68c8;
}

.custom-experience {
  display: flex;
  justify-content: space-between;
}

.custom-border {
  border: 1px solid #000;
}
