.taskmanagement__container {
  display: flex;
  flex-wrap: wrap;

  .taskmanagement__selectProject {
    label {
      margin-bottom: 8px;
    }

    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .taskmanagment__calenderContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    padding-block: 1rem;

    .taskmanagement__piechart,
    .taskmanagement__graph {
      width: 48%;
    }
  }

  .taskmanagement_table {
    width: 100%;
  }
}
