.local_video_stream_peer_present {
    position: absolute;
    top:100%;
    right: 10px;
    width: 250px !important;
    height: 200px !important;
    transform:scaleX(-1) translateY(-95%);
}

.local_video_stream_peer_absent {
    width: 100%;
    height: 100%;
    /* width: auto; */
    transform: scaleX(-1);

}
.screenshare_view{
    width:70%;
    height:100%;
    padding:10px;
}
.screenshare_view video{
    width:100%;
    height:100%;
    object-fit:cover;
}
.remote_videos_container{
    width: 100%;
    height:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: width ease 2s;
    justify-content: center;
}
.remote_videos_container_with_screenShare{
    width:30%;
    height:100%;
    display: flex;
    flex-direction: column;
}
#local-video video{
    height:auto;
    /* transform: scaleX(-1) !important; */
    object-fit: cover !important;

}
.double_remote_video_container>div:first-child>video{
    object-fit: cover !important;
}
#local-video>div:first-child {
    background-color: transparent !important;
    /* height: auto !important; */
}

.mute_mic {
    width: 12px;
    height: 12px
}

.mute_mic_container {
    position: absolute;
    top: 10%;
    right: 10%;
    transform: translate(-10%, -20%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: lavender;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remote_video_section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: width ease-out 2s;
    justify-content: center;
}

._single_remote_video_container {
    width: 100%;
    height: 100%;
    min-width: 350px;
    position: relative;
    border: 1px solid lavender;
    border-radius: 10px;
    background-color: lightslategray;
    width: auto;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.double_remote_video_container {

    position: relative;
    border: 1px solid lavender;
    border-radius: 19px;
    background-color: lightslategray;
    width: calc(50% - 2px);
    height: 100%;
    overflow: hidden;
    /* transition: width ease-out 2s; */
    /* width:auto; */
}

.remote_video {

    transform: scaleX(-1);
    width: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transition: width ease-out 2s; */
}

.call_actions_tab {
    width: 400px;
    height: 80px;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 10px;
    position: absolute;
    background: rgba(0, 0, 0, .4);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}

.call_action_button_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #085394;
    cursor:pointer
}

.call_action_button_icon_container>img {
    width: 28px;
    height: 28px;
}

.meeting_layout {
    width: 100%;
    height: calc(100% - 0px);
    overflow: hidden;
    position: relative;
    display: flex;
}
.search_container .uiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 2px solid white;
}
.search_container .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    color:white;
}
.search_container .css-953pxc-MuiInputBase-root-MuiInput-root::before{
    border-bottom: 1px solid white;
}
.search_container .css-953pxc-MuiInputBase-root-MuiInput-root::before,.search_container .css-953pxc-MuiInputBase-root-MuiInput-root::after :hover{
    border-bottom: 2px solid white;
}
.search_container .css-953pxc-MuiInputBase-root-MuiInput-root::after {
    border-bottom: 2px solid white;}
.chat_section {
    width: 30%;
    height: 100%;
    transition: width ease 2s;
    background: rgb(8, 83, 148 );
    border-radius: 10px;
    padding:20px;
    display:flex;
    flex-direction: column;
}

.remote_video_section_with_chat_box {
    width: 70% !important;
    transition: width ease 2s;
}
.people_head{
    font-size: 20px;
    font-weight: 600;
    color: white;
}
.add_people_button{
    display:flex;
    width:150px;
    font-size:20px;
    color:rgb(8, 83, 148 );
    height:30px;
    border-radius: 50px;
    background:rgb(250,250,250,0.9);
    align-items:center;
    justify-content: center;
    cursor:pointer
}
.all_people_invited_section{
    width:100%;
    height:65%;
    overflow: auto;
    /* padding:10px; */
}
.button_section {
    width: 100%;
    margin-top: auto;
    max-height: 80px;

    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.chat_individual {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 50px;
    overflow: break-words;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin-bottom: 10px;
    background-color: #0f406b;
    border-radius:5px;
    color:white;
    width:auto;
    padding:10px;
    padding-bottom:5px;
    max-width:150px;
}
.timestamp_text{
    font-size:8px;
    width:100%;
    text-align: right;
}


.chat_inputs_box {
    width: 100%;
    height: 75%;
    overflow-y: auto;
    margin-top: 10px;
}
.right_section_options {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 10px;
}
.button_section input {
    width: 100%;
    height: 40px;
    padding: 4px;

}

.list_item_employee{
    color:white;
    font-size:16px;

}
.MuiListItemIcon-root{
    min-width: none;
    margin-left:10px;
}
.MuiListItemIcon-root{
    min-width: auto !important;
    margin-left:10px;

}
.MuiListItemAvatar-root{
    min-width: auto !important;
}
.MuiListItemText-root{
    display: flex;
    margin-left: 10px;
    align-items: center;
}