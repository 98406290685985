/* sendNotification.css */

/* General styles */
.sendNotificationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .sendNotificationContent {
    width: 75%;
    padding: 20px;
  }
  
  /* Header styles */
  .sendNotificationHeader {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* Modal styles */
  .sendNotificationCloseButton {
    background-color: #ff0000;
    font-size: 24px;
    width: 36px;
    height: 36px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Form styles */
  .sendNotificationForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sendNotificationInputBox {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
 
  }
  
  .sendNotificationSelectOption {
    width: 100%;
    padding: 7px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #c9c9c9;
    background-color: #f8f8f8;
  }
  
  .sendNotificationInputBox input[type="text"],
  .sendNotificationInputBox select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #c9c9c9;
  }
  
  .sendNotificationInputBox button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2980b9;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;

  }

  .sendNotificationSelectedListContainer {
    max-height: 70px; /* Adjust the max height as needed */
    overflow-y: auto;
    width: 200px; /* Adjust the fixed width as needed */
    border: 1px solid #ccc;
    padding: 5px;
  }

  ::-webkit-scrollbar{
    display: none;
  }
  
  
  /* Selected employees styles */
  .sendNotificationInputBox ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sendNotificationInputBox ul li {
    margin-top: 5px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .sendNotificationContainer {
      flex-direction: column;
      align-items: center;
    }
  
    .sendNotificationContent {
      width: 100%;
    }
    
    .sendNotificationForm {
      width: 100%;
    }
  }
  

  .to_title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 50px;
  }


  .notification_box{
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 80vh;
    overflow-y: auto;
  }

  .notification{
    display: flex;
    flex-direction: column;
   
  }