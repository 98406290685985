@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 207.86 89.74 30.59;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

.css-1hb7zxy-IndicatorsContainer {
  height: 100% !important;
}

.css-3w2yfm-ValueContainer {
  height: 100% !important;
  overflow: scroll !important;
  /* width: 80% important !important; */
  align-items: flex-start !important;
}

.css-1p3m7a8-multiValue {
  max-width: 45% !important;
}

.css-1hb7zxy-IndicatorsContainer {
  /* width:20%!important; */
}

.css-1xc3v61-indicatorContainer {
  /* width:50%!important;
  padding: 0px !important;
  display: flex !important;
    align-items: center !important; */

}

.table-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

e
td {
  /* border: 1px solid #ddd; */
  padding: 12px;
}

/* Remove double borders between cells */
th:not(:last-child),
td:not(:last-child) {
  border-right: none;

}

tr:not(:last-child) td {
  border-bottom: none;
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    overflow: hidden;
  }
}

td {
  text-align: center !important;
  vertical-align: middle;
  min-width: 100px !important;
}

th {
  min-width: 100px !important;

}

.canvasjs-chart-container {
  /* height:100% !important; */
  width: 100% !important;



}

.error {
  color: red;
  font-size: 12px;
}

.td_progress .RCP {
  height: 45px !important;
  width: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-progress-value {
  color: white !important;
  border-radius: 6px !important;
  height: 10px;

  transition: width 0.5s ease-in-out;
}

::-webkit-progress-bar {
  color: transparent;
  border-radius: 6px !important;
  /* height: 10px; */
  background-color: transparent;
  /* border: 1px solid lightgreen */
}

progress {
  display: block;
  width: 100%;
  border-radius: 6px !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-canvas {
  width: 100% !important;
}

.react-datepicker-popper {
  z-index: 13
}

input {
  padding: 10px;
}

input[type="file"] {
  padding: 0px !important;
}