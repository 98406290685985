/* newcalender.css */

/* Remove hover color */
.fc .fc-daygrid-day:hover,
.fc .fc-timegrid-slot:hover,
.fc .fc-col-header-cell:hover {
  background-color: transparent !important;
}

/* Bold heading for day and time with 2px border */
.fc .fc-col-header-cell-cushion,
.fc .fc-timegrid-axis-cushion {
  font-weight: bold;
}

.fc .fc-col-header-cell,
.fc .fc-timegrid-axis {
  /* border-width: 2px; */
  border: 1px solid rgb(200, 198, 198);
  border-style: solid;
}

/* Set background of each row and column to white and border to 1px */
.fc .fc-timegrid-slot,
.fc .fc-daygrid-day,
.fc .fc-timegrid-slot-lane {
  background-color: white;
  border: 1px solid rgb(200, 198, 198);
}

.fc .fc-timegrid-slot {
  height: 40px;
}

/* Vertical lines for the time grid */
.fc .fc-timegrid-col-frame {
  border: 1px solid rgb(200, 198, 198);
}

/* Vertical lines for the day grid */
.fc .fc-daygrid-day-frame {
  border-right: 1px solid rgb(200, 198, 198);
}

/* Border for the header cells */
.fc .fc-col-header-cell {
  border-right: 1px solid rgb(200, 198, 198);
  border-bottom: 1px solid rgb(200, 198, 198);
}

/* Style for all-day event section */
.fc .fc-daygrid {
  border-top: 1px solid rgb(200, 198, 198);
}

/* Event styles */
.event-meeting {
  border-color: #ff7f50 !important;
  border: 2px solid;
  background-color: #fff !important;
  color: black;
}

.event-birthday {
  /* border-color: #ff69b4 !important; */
  border: 2px solid;
  /* background-color: #fff !important; */
  color: black;
}

.fc-v-event .fc-event-title-container {
  color: black;
  /* background-color:rgba(240, 162, 103, 0.4) ; */
  font-size: 14px;
}

.fc-v-event .fc-event-time {
  color: white;
  border: 1px solid orange;
  width: 70px;
  padding: 2px 4px;

  font-size: 9px;
  background-color: orange;
  border-radius: 4px;
}
.fc-v-event .fc-event-main-frame {
  /* background-color: rgba(240, 162, 103, 0.4); */
}
.fc-h-event .fc-event-title-container {
  color: "black";
  /* background-color: rgba(242, 40, 215, 0.2); */
}
.fc .fc-daygrid-day-frame {
  background-color: white;
}

.event-leave {
  border: 2px solid;
  background-color: rgba(143, 194, 236, 0.8) !important;
  color: black;

  border-color: #4682b4 !important;
}
