
/* sidebar.css */


.sidebar-item {
  width: 100%;
  padding: 15px;
  
  text-align: center;
  cursor: pointer;
  background-color: #eaf2ff;
  
  transition: all 0.3s ease;
}

.sidebar-item.active {
  background-color: #3f51b5;
  color: white;
  transform: scale(1.1);
}

/* .sidebar-item:hover {
  background-color: #3f51b5;
  color: white;
} */
