.home {
  display: flex;
  height: 100vh;

  .homeContainer {
    flex: 6;
    background-color: #eaf2ff;
    // overflow: auto;
    max-width:calc(100% - 175px);



    padding: 5px 20px;
    .widgets,
    .charts {
      display: flex;
      padding: 10px;
      gap: 20px;
    }
.layout_section{
  width:100%;
  min-height: calc(100% - 200px);
  height: calc(100% - 100px);
  max-height: calc(100% - 60px);
  overflow: auto;
}
    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 0px 0px 5px 2px rgb(235 235 235 / 47%);
      box-shadow: 0px 0px 5px 2px rgb(235 235 235 / 47%);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }
}
@media (max-width: 1080px) {
  .home .homeContainer .widgets,
  .home .homeContainer .charts {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
}

.homeContentTitle {
  font-size: 20px;
  font-weight: 700;
}

// Add this CSS to your stylesheets (e.g., home.scss)
.starAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  width: 20px;
  height: 20px;
  background-color: #ffd700; /* Yellow color */
  clip-path: polygon(
    50% 0%,
    61.8% 35.3%,
    98.2% 35.3%,
    68.2% 57.3%,
    79.1% 91.2%,
    50% 70.9%,
    20.9% 91.2%,
    31.8% 57.3%,
    1.8% 35.3%,
    38.2% 35.3%
  );
  animation: spin 2s linear infinite;
  margin: 0 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.progressContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

@media (max-width: 768px) {
  .progressContainer {
    justify-content: center;
  }

  .graph-container {
    width: 100%;
  }
}
