.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f0f0f0;
}

.title {
  font-weight: bold;
}

.project_table table tr th {
  background-color: white !important;
  padding-top: 0px !important;
  text-align: left !important;
  max-width: 100px;
  min-width:100px;
  height:45px;
  z-index:999;
}
.project_table table tr th:first-child,.project_table table tr th:nth-child(2){
  min-width:120px;
  max-width:120px;
}
.project_table table tr th{
  background-color: #ffff;
}
.project_table table tr {
  background-color: white !important;
  min-height: 45px !important;
  max-height: 45px !important;

}

.project_table table tbody tr td {
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  min-width:100px;
  min-height: 30px !important;
  max-height: 30px !important;
}
.project_table table tbody tr td:first-child,.project_table table tbody tr td:nth-child(2){
  min-width:120px;
  max-width:120px;
}

.add-task {
  width: 8vw;
}

.table-container {
  max-height: 500px;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #f0f0f0;
}

th,
td {
  padding: 8px;
  /* text-align: left; */
}

th {
  position: sticky;
  top: 0;
  /* Stick the header to the top */
  /* z-index: 2; Ensure header is above other elements */
  background-color: #ffff;
}

tbody tr:nth-child(even) {
  background-color: #ffff;
}

tbody tr:hover {
  /* background-color: #e0e0e0; */
}


