.search_icon {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-radius: 20px;
  border: 1px solid black;
  background-color: #f4f7fc;
  height: 30px;
  /* box-shadow: 2px 2px 5px rgb(164, 155, 155); */
  cursor: pointer;
  border: none;
}
.search_icon > input {
  outline: none;
  border: none;
  background: none;
  color: #9a9a9a;
  font-size: 14px;
}

.search_icons__ {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
